export const main = teamName => [
	{
		iconClass: "fa-solid fa-house",
		label: "Home",
		link: "/",
		allowedTeams: [
			{
				team: "admin",
				with: ["superadmin"],
			},
		],
	},
	{
		iconClass: "fa-solid fa-calendar-plus",
		label: "+ Create Bookings",
		link: "/create-bookings",
		mainMobileItem: teamName == "provider",
		allowedTeams: ["admin", "provider"],
	},
	{
		iconClass: "fa-solid fa-calendar",
		label: teamName == "worker" ? "Upcoming Bookings" : "Bookings",
		link: "/bookings",
		mainMobileItem: true,
		allowedTeams: [
			"admin",
			"provider",
			{
				team: "worker",
				userIs: "active",
			},
		],
	},
	{
		link: "/reserve-bookings",
		label: "Available bookings",
		iconClass: "fa-solid fa-list",
		mainMobileItem: teamName == "worker",
		allowedTeams: [
			{
				team: "worker",
				userIs: "active",
			},
		],
	},
	{
		iconClass: "fa-solid fa-hospital",
		label: "Providers",
		link: "/providers",
		mainMobileItem: teamName == "admin",
		allowedTeams: ["admin", "provider"],
	},
	{
		iconClass: "fa-solid fa-user-nurse",
		label: "Users",
		link: "/users",
		mainMobileItem: teamName == "admin",
		allowedTeams: ["admin"],
	},
	{
		link: "/invoices",
		label: "Invoices",
		iconClass: "fa-solid fa-dollar-sign",
		mainMobileItem: teamName == "worker",
		allowedTeams: [
			{
				team: "worker",
				userIs: "active",
			},
		],
	},
	{
		iconClass: "fa-solid fa-comment",
		label: "Communications",
		link: "/communications",
		allowedTeams: [
			{
				team: "admin",
				with: ["communications"],
			},
		],
	},
	{
		iconClass: "fa-solid fa-map-location-dot",
		label: "Locate contractors",
		link: "/locate-contractors",
		allowedTeams: ["admin", "provider"],
	},
	{
		iconClass: "fa-solid fa-phone",
		label: "CRM",
		link: "/crm",
		allowedTeams: ["admin"],
	},
	{
		iconClass: "fa-solid fa-chart-line",
		label: "Analytics",
		link: "/analytics",
		allowedTeams: [
			{
				team: "*",
				with: ["analytics"],
			},
		],
	},
	{
		iconClass: "fa-solid fa-ranking-star",
		label: "NPS",
		link: "/nps",
		allowedTeams: ["admin"],
	},
	{
		iconClass: "fa-solid fa-gauge",
		label: "Dashboard",
		link: "/dashboard",
		allowedTeams: [
			"admin",
			{
				team: "*",
				with: ["dashboard"],
			},
		],
	},
	{
		iconClass: "fa-solid fa-file-invoice-dollar",
		label: "Payments",
		link: "/payments",
		allowedTeams: [
			{
				team: "admin",
				with: ["accounts"],
			},
		],
	},
];

export const account = () => [
	{
		link: "/user/details",
		label: "Details",
		allowedTeams: ["admin", "provider", "worker"],
	},
	{
		link: "/user/profile",
		label: "My Profile",
		allowedTeams: ["worker"],
	},
	{
		link: "/user/settings",
		label: "Settings",
		allowedTeams: ["admin", "provider", "worker"],
	},
];
